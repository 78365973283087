<template>
  <div class="page-scan">
    <div class="scan-box">
        <!-- 返回 -->
        <div class="back-btn" @click="goBack">
            <i class="el-icon-arrow-left" style="font-size: 25px;vertical-align: middle;"></i>取消
        </div>
        <!-- 扫码区域 -->
        <video ref="video" id="video" class="scan-video" autoplay></video>
        <div class="scan-img" v-show="scanFrameShow">
            <div class="scan-frame">
                <!-- <span class="left-t"></span>
                <span class="right-t"></span>
                <span class="left-b"></span>
                <span class="right-b"></span> -->
                <span class="cross-line"></span>
            </div>
        </div>
        <!-- 提示语 -->
        <div v-show="tipShow" class="scan-tip"> {{tipMsg}} </div>
    </div>

  </div>
</template>
 
<script>
import { BrowserMultiFormatReader } from '@zxing/library'

export default {
    name: 'scanCodePage',
    data() {
        return {
            codeReader: null,
            scanText: '',
            scanFrameShow: false,
            tipMsg: '正在尝试识别....',
            tipShow: false
        }
    },
    watch: {
        '$route'(to, from) {
            if(to.path == '/scanCodePage') {
                this.codeReader = new BrowserMultiFormatReader();
                this.openScanTwo();
            }
        }
    },
    methods: {
        async openScan() {
            this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
                this.tipShow = true;
                this.tipMsg = '正在调用摄像头...';
                console.log('videoInputDevices', videoInputDevices);
                // 默认获取第一个摄像头设备id
                let firstDeviceId = videoInputDevices[0].deviceId;
                // 获取第一个摄像头设备的名称
                const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
                if (videoInputDevices.length > 1) {
                    // 判断是否后置摄像头
                    if (videoInputDeviceslablestr.indexOf('back') > -1) {
                        firstDeviceId = videoInputDevices[0].deviceId;
                    } else {
                        firstDeviceId = videoInputDevices[1].deviceId;
                    }
                }
                this.decodeFromInputVideoFunc(firstDeviceId);
            }).catch(err => {
                this.tipShow = false;
                console.error(err);
            });
        },
        async openScanTwo() {
            this.codeReader = await new BrowserMultiFormatReader();
            this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
                this.tipShow = true;
                this.tipMsg = '正在调用摄像头...';
                console.log('videoInputDevices', videoInputDevices);
                // 默认获取第一个摄像头设备id
                let firstDeviceId = videoInputDevices[0].deviceId;
                // 获取第一个摄像头设备的名称
                const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
                if (videoInputDevices.length > 1) {
                    // 判断是否后置摄像头
                    if (videoInputDeviceslablestr.indexOf('back') > -1) {
                        firstDeviceId = videoInputDevices[0].deviceId;
                    } else {
                        firstDeviceId = videoInputDevices[1].deviceId;
                    }
                }
                this.decodeFromInputVideoFunc(firstDeviceId);
            }).catch(err => {
                this.tipShow = false;
                console.error(err);
            });
        },
        decodeFromInputVideoFunc(firstDeviceId) {
            this.codeReader.reset(); // 重置
            this.scanText = '';
            this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
                this.tipMsg = '正在尝试识别...';
                this.scanText = '';
                setTimeout(() => {
                    this.scanFrameShow = true;
                }, 500)
                if (result) {
                    // console.log('扫描结果', result);
                    this.scanText = result.text;
                    if (this.scanText) {
                        this.tipShow = false;
                        this.scanFrameShow = false;
                        // 自己的逻辑
                        let that = this;
                        this.$post('useVisitorQRCode', {
                            QRCodeMessage: that.scanText
                        }).then((data) => {
                            if (data.resultCode == 1) {
                                let resultData = data.resultMapList;
                                that.$router.push({ name: 'home', params: { data: JSON.stringify(resultData) } })

                            } else {
                                that.$message.error(data.resultMsg);
                            }
                        }).catch(err => {
                            alert(err)
                        })

                        // 识别完成之后停止摄像头
                        // this.codeReader.reset(); // 重置
                    }
                }
                if (err && !(err)) {
                    this.tipMsg = '识别失败';
                    setTimeout(() => {
                        this.tipShow = false;
                    }, 2000)
                    console.error(err);
                }
            });
        },
        // 返回上一页
        goBack() {
            // this.codeReader = null;
            // this.$destroy();
            this.$router.back();
        },


    },
    created() {
        this.codeReader = new BrowserMultiFormatReader();
        this.openScan();
    },
    destroyed() {
        this.codeReader.reset();
    }
}
</script>
 
<style scoped>
.page-scan {
    overflow-y: hidden;
}
.scan-box {
    width: 100vw;
    height: 100vh;
}
.back-btn {
    position: absolute;
    top: 50px;
    left: 30px;
    color: #fff;
    font-size: 36px;
}
/* 扫描区域 */
.scan-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.scan-img {
    /* position: fixed; */
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 65%;
    /* top: 40%;
    left: 50%;
    width: 500px;
    height: 500px;
    margin-top: -200px;
    margin-left: -250px; */
    z-index: 9;
}
/* .scan-frame {
    width: 100%;
    height: 100%;
    position: relative;
} */
.left-t, .right-t, .left-b, .right-b {
    position: absolute;
    width: 80px;
    height: 80px;
}
.left-t {
    top: 0;
    left: 0;
    border-top: 2px solid #17B1B7;
    border-left: 2px solid #17B1B7;
}
.right-t {
    top: 0;
    right: 0;
    border-top: 2px solid #17B1B7;
    border-right: 2px solid #17B1B7;
}
.left-b {
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #17B1B7;
    border-left: 2px solid #17B1B7;
}
.right-b {
    bottom: 0;
    right: 0;
    border-bottom: 2px solid #17B1B7;
    border-right: 2px solid #17B1B7;
}
.cross-line {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 255, 51, 0) 80%, #00ff33 180%);
    /* width: 600px;
    height: 10px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #5DDDD3, rgba(255,255,255,0)); */
    position: absolute;
    top: 0;
    /* left: -50px; */
    left: 0;
    animation: identifier_p 2s infinite;
    /* animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
    animation-delay: 1.4s; */
}
@keyframes identifier_p {
    0% {
        top: -90%;
    }
    100% {
        top: 0%;
    }
}
/* @keyframes identifier_p {
    0% {
        top: 0%;
    }
    50% {
        top: 100%;
    }
    100% {
        top: 0%;
    }
} */
.scan-tip {
    position: absolute;
    bottom: 12%;
    left: 0;
    width: 100vw;
    text-align: center;
    color: #fff;
    font-size: 30px;
}
</style>